export function editdata({ curlang}) {

    return [
        {
            type: 'text',
            attr: {
                name: 'title',
                hintText: curlang.edithinttitle,
                floatingLabelText: curlang.edithinttitle,
                validations: {
                    minLength: 2,
                    maxLength: 200
                },
                validationErrors: {
                    minLength: curlang.mincharerror(2),
                    maxLength: curlang.maxcharerror(200)
                },
                style: function(state, props) {
                    const { hiddencontent } = props
                    if (hiddencontent) return { display: 'none', width: '100%' }
                    return { width: '100%' }
                },
                onChange: function(state, props) {
                    return function(e, value) {
                        const { post } = props
                        post.title = value
                    }
                },
                required: true,
                value: function(state, props) {
                    const { post } = props
                    return (post && post.title) ? post.title : ''
                }
            }
        }, {
            type: 'button',
            attr: {
                type: 'submit',
                name: 'submit',
                label: function(state, props) {
                    const { restoretext } = props
                    return (state.processing) ? curlang.savepost : (restoretext) ? restoretext : curlang.savepost
                },
                style: function(state, props) {
                    const { hiddencontent } = props
                    if (hiddencontent) return { marginTop: 0 }
                    return { marginTop: 32 }
                },
                disabled: function(state = {}) {

                    return !state.canSubmit
                }
            }
        }
    ]
}
