import React from 'react'
import createReactClass from 'create-react-class'

import ps from '../../../../themes/components/Wapplrpost.css'
import avs from '../../../../themes/components/Wapplravatar.css'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'
import Wapplrdialog from '../../../components/Wapplrdialog'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'

import fetchs from '../../../services/fetchs'
import { getPostStatusData } from '../../../server/poststatus/statuses'

const WapplrpostEdit = wapplrcomponents.editpost

const Post = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            isMounted: false,
            post: null
        }
    },
    setSaved: function() {
        const editpost = this.refElements['editpost']
        if (editpost && editpost.updatePost) editpost.updatePost()
    },
    componentDidMount: function() {

        const { response, curlang = {}, history } = this.props

        const {
            savepostdefaultfail = 'Sorry, there was an issue save your post, please try again',
            savepostsuccessmessage = 'Your post has been saved',
            deletepostdefaultfail = 'Sorry, there was an issue delete your post, please try again',
            deletepostsuccessmessage = 'Your post has been deleted'
        } = curlang

        const post = this.getPost(response)
        const getPostLink = this.getPostLink

        const snack = this.snack
        const setSaved = this.setSaved
        const done = this.done

        this.savefetch = fetchs.saveimage({
            getHelpers: this.getHelpers,
            history: history,
            success: function({ response, processingEnd }) {
                snack(savepostsuccessmessage)
                if (processingEnd) processingEnd(false)
                if (response.status) post.status = response.status
                setSaved()
                setTimeout(function() {
                    done(getPostLink())
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || savepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        const dialogClose = this.dialogClose

        this.deletefetch = fetchs.deleteimage({
            getHelpers: this.getHelpers,
            history: history,
            success: function({ response }) {
                dialogClose()
                snack(deletepostsuccessmessage)
                setTimeout(function() {
                    done(getPostLink({ id: response.postId }))
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || deletepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.setState({
            isMounted: true,
            post: post
        })

    },

    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },

    //Close

    close: function() {

        const { state } = this.getHelpers()

        const getPostLink = this.getPostLink

        const historyPush = this.historyPush

        if (state.lasturl) {
            historyPush(state.lasturl)
        } else {
            historyPush(getPostLink())
        }
    },

    done: function(post) {

        const { state } = this.getHelpers()

        const getPostLink = this.getPostLink

        const historyPush = this.historyPush

        if (state.lasturl) {
            historyPush(state.lasturl)
        } else {
            historyPush(getPostLink(post))
        }
    },

    //Edit functions

    savefetch: function() {
    },
    savepost: function(data, resetForm, invalidateForm, refs = {}) {

        const post = this.state.post


        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) processingStart(true)

        if (post && post.id) {
            data.id = post.id
            this.savefetch(data, resetForm, invalidateForm, refs)
        }
    },
    deletefetch: function() {
    },
    deletepost: function({ post }) {

        const { curlang } = this.props

        const {
            deletepostsubtitle = 'Delete post',
            deletepostquestion = 'Are you sure want to delete your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const deletefetch = this.deletefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: deletepostsubtitle,
            text: deletepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = { id: post.id }
                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                deletefetch(data, resetForm, invalidateForm, refs)

            }
        })
    },

    //image helpers

    getPostLinkObject: function() {
        const post = this.state.post
        if (post && post.id) {
            return {
                pathname: '/image/' + post.id
            }
        }
        return {}
    },
    getPostLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/image/' + post.id
        }
        return ''
    },
    getPost: function(response) {
        return (response && response.currentimage && response.currentimage[0]) ? response.currentimage[0] : null
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    setStatusName: function({ statusname }) {
        let s = statusname
        const me = this.getMe()
        const admin = me && me.isAdmin || me && me.isEditor

        if (!admin) {
            if (s === 'deleted itself') {
                s = 'deleted'
            }
            if (s === 'created') {
                s = 'published'
            }
            if (s === 'required data') {
                s = 'published'
            }
            if (s === 'approved') {
                s = 'published'
            }
        }

        return s
    },
    getPostStatus: function({ post }) {
        const { curlang } = this.props
        return getPostStatusData({ post, curlang })
    },
    getEditData: function() {
        const { editdata } = this.props
        return editdata.image
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },

    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div></div>
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    readycallback: function() {
        const editpost = this.refElements['editpost']
        if (editpost && editpost.setReady) editpost.setReady()
    },

    //Publicicon
    getpublicicon: function({ post }) {

        const share = post && post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },

    getstatusname: function({ post }) {
        const { statusname } = this.getPostStatus({ post })
        return statusname
    },
    getdeletebutton: function({ post }) {
        const { deletebutton } = this.getPostStatus({ post })
        return deletebutton
    },
    getrestoretext: function({ post }) {
        const { curlang } = this.props
        const { deletebutton } = this.getPostStatus({ post })
        return (deletebutton) ? null : curlang.saveandrestore
    },
    render: function() {

        const { history, helpers, getthumb, getthumbstyle } = this.props

        const savepost = this.savepost
        const deletepost = this.deletepost
        const restorepost = this.restorepost




        const data = this.getEditData()
        const post = this.state.post
        const close = this.close
        const setRef = this.setRef
        const author = (post && post.author) ? post.author : null
        const me = this.getMe()

        const getpublicicon = this.getpublicicon
        const getstatusname = this.getstatusname
        const getdeletebutton = this.getdeletebutton
        const getrestoretext = this.getrestoretext

        const bgstyle = (post && post.id) ? { backgroundImage: 'url(\'' + post.cover + '\')' } : null

        return (
            <div>
                {(author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor) ?
                    <div>
                        <div style={bgstyle} className={ps.postimagecontainer}></div>
                        <WapplrpostEdit history={history} style={ps} post={post} showauthor={true} avatarstyle={avs}
                                        setRef={setRef}
                                        className={'cardtype'}
                                        enablehiddencontent={false}
                                        save={savepost}
                                        deletepost={deletepost}
                                        restorepost={restorepost}
                                        data={data}
                                        close={close}
                                        getstatusname={getstatusname}
                                        getdeletebutton={getdeletebutton}
                                        getrestoretext={getrestoretext}
                                        getpublicicon={getpublicicon}
                                        formprops={{ helpers: helpers }}
                                        getthumb={getthumb}
                                        getthumbstyle={getthumbstyle}
                        />
                    </div>
                    :
                    null
                }
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
                <Wapplrdialog setRef={setRef} className={ps.dialog} />
            </div>
        )
    }
})

export default createReactClass({
    render: function() {

        return (
            <TemplateDefault {...this.props} >
                <Post {...this.props} />
            </TemplateDefault>
        )
    }
})
