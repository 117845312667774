import React from 'react'
import createReactClass from 'create-react-class'

import gs from '../../../../themes/components/Wapplrgallery.css'
import Wapplrdialog from '../../../components/Wapplrdialog'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'

import wapplrcomponents from '../../../../components'

import DeleteIcon from 'material-ui/svg-icons/action/delete-forever'
import PublisIcon from 'material-ui/svg-icons/editor/publish'
import IconButton from 'material-ui/IconButton'
import EditIcon from 'material-ui/svg-icons/editor/mode-edit'
import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'
import StatusIcon from 'material-ui/svg-icons/editor/vertical-align-center'

import fetchs from '../../../services/fetchs'
import { getPostStatusData } from '../../../server/poststatus/statuses'

import MetaData from '../../../components/MetaData'
import mds from '../../../components/MetaData/MetaData.css'

import TimeIcon from 'material-ui/svg-icons/device/access-time'

import AdminPost from '../../../components/Admin/Post.js'
import adminpostfunctions from '../../../components/Admin/postfunctions.js'

const Wapplrgallery = wapplrcomponents.gallery

export default createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const posts = this.getPosts()
        const postsdata = this.getPostsData()

        const posttype = this.getPostType()
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath

        return {
            isMounted: false,
            posts: posts || null,
            postsdata: postsdata || null,
            posttype: posttype || '',
            posttypepath: posttypepath || '',
            posttypename: posttypename || ''
        }
    },
    getHelpers: function() {

        const { construct } = this.props
        const { helpers } = construct.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getPostLinkObject: function({ post }) {
        if (post && post.id) {
            return {
                pathname: '/image/' + post.id
            }
        }
        return {}
    },
    getPostLink: function({ post }) {
        if (post && post.id) {
            return '/image/' + post.id
        }
        return ''
    },
    getPostEditLinkObject: function({ post }) {
        if (post && post.id) {
            return {
                pathname: '/image/edit/' + post.id
            }
        }
        return {}
    },
    getPostEditLink: function({ post }) {
        if (post && post.id) {
            return '/image/edit/' + post.id
        }
        return ''
    },
    historyPushFetch: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    setPosts: function(a) {
        this.setState({ posts: a.posts })
    },
    componentDidMount: function() {

        const { construct, history } = this.props
        const { searchData, curlang } = construct.props

        const {
            savepostdefaultfail = 'Sorry, there was an issue save your post, please try again',
            savepostsuccessmessage = 'Your post has been saved',
            deletepostdefaultfail = 'Sorry, there was an issue delete your post, please try again',
            deletepostsuccessmessage = 'Your post has been deleted'
        } = curlang

        this.historyHelpers = searchData.getHistoryHelpers()



        const getHelpers = this.getHelpers
        const dialogClose = this.dialogClose
        const dialogOpen = this.dialogOpen
        const snack = this.snack
        const historyPush = this.historyPushFetch
        const state = this.state
        const setPosts = this.setPosts

        adminpostfunctions({
            posttype: 'image',
            t: this,
            curlang,
            fetchs,
            history,
            getHelpers,
            dialogClose,
            dialogOpen,
            snack,
            state,
            setState: setPosts
        })

        this.restorefetch = fetchs.saveimage({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                dialogClose()
                snack(savepostsuccessmessage)
                setTimeout(function() {
                    historyPush(history.location.pathname + history.location.search)
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || savepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.deletefetch = fetchs.deleteimage({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                dialogClose()
                snack(deletepostsuccessmessage)
                setTimeout(function() {
                    historyPush(history.location.pathname + history.location.search)
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || deletepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.setState({
            isMounted: true
        })

    },
    componentWillUnmount: function() {
        this.setState({ isMounted: false })
    },

    getPostType: function() {
        const { construct } = this.props
        const { helpers } = construct.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return state.query.posttype || ''
    },
    getPostTypePath: function() {
        const { construct } = this.props
        const { posttypesData = {} } = construct.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].path) ? posttypesData[posttype].path : ''
    },
    getPostTypeName: function() {
        const { construct } = this.props
        const { posttypesData = {} } = construct.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].pluralname) ? posttypesData[posttype].pluralname : ''
    },
    historyPush: function(page) {
        if (page) {
            const { store } = this.getHelpers()
            const historyHelpers = this.historyHelpers
            const h = historyHelpers.getDataFromHistory({ store })
            h.page = page
            const searchText = historyHelpers.objectToString(h)
            historyHelpers.historyPush({ search: searchText }, true)
        }
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getPosts: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.archive && state.response.archive.posts) ? state.response.archive.posts : null
    },
    getPostsData: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.archive && state.response.archive.data) ? state.response.archive.data : null
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getPostStatus: function({ post }) {
        const { construct } = this.props
        const { curlang } = construct.props
        return getPostStatusData({ post, curlang })
    },
    deleteicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)

        if (deleteorrestore === 'delete' && canEdit) {
            const deletefunction = this.deletefunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        deletefunction(e, { post })
                    }}
                    onTouchStart={function() {

                    }}
                    className={style.cardrightbuttonicon}
                >
                    <DeleteIcon />
                </IconButton>
            )
        }
        return null
    },
    deletefetch: function() {
    },
    deletefunction: function(e, d) {
        const { construct } = this.props
        const { curlang = {} } = construct.props
        const { post } = d

        const {
            deletepostsubtitle = 'Delete post',
            deletepostquestion = 'Are you sure want to delete your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const deletefetch = this.deletefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: deletepostsubtitle,
            text: deletepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = { id: post.id }
                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                deletefetch(data, resetForm, invalidateForm, refs)

            }
        })

    },
    restorefetch: function() {
    },
    restorefunction: function(e, d) {

        const { construct } = this.props
        const { curlang = {} } = construct.props
        const { post } = d

        const {
            restorepostsubtitle = 'Restore',
            restorepostquestion = 'Are you sure want to restore your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const restorefetch = this.restorefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: restorepostsubtitle,
            text: restorepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = post
                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                restorefetch(data, resetForm, invalidateForm, refs)

            }
        })
    },
    restoreicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)

        if (deleteorrestore === 'restore' && canEdit) {
            const restorefunction = this.restorefunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        restorefunction(e, { post })
                    }}
                    onTouchStart={function() {

                    }}
                    className={style.cardrightbuttonicon}
                >
                    <PublisIcon />
                </IconButton>
            )
        }
        return null
    },

    editfunction: function(e, d) {
        const { post } = d
        const editlink = this.getPostEditLink({ post })
        this.historyPushFetch(editlink)
    },
    editicon: function({ post, style }) {
        const { deleteorrestore } = this.getPostStatus({ post })
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)

        if (deleteorrestore && canEdit) {
            const editfunction = this.editfunction
            return (
                <IconButton
                    onTouchTap={function(e) {
                        editfunction(e, { post })
                    }}
                    onTouchStart={function() {

                    }}
                    className={style.cardrightbuttonicon}
                >
                    <EditIcon />
                </IconButton>
            )
        }
        return null
    },
    publicicon: function({ post}) {

        const share = post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },

    contentcomponent: function({ post}) {
        if (post) {

            const { construct } = this.props
            const { curlang } = construct.props

            const { statusname, approveenable, banenable } = this.getPostStatus({ post })
            const me = this.getMe()
            const author = (post && post.author) ? post.author : null
            const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)
            const isAdmin = (me && me.isAdmin || me && me.isEditor)

            const approvefunction = this['approvefunction' + 'image']
            const banfunction = this['banfunction' + 'image']

            const data = [
                { icon: <TimeIcon />, name: curlang.metauploaddate, value: 'publishedDate' },
                {
                    icon: <StatusIcon />, name: curlang.status, value: function(props) {

                        const { post = {}, style } = props
                        if (canEdit) {
                            return (
                                <div>
                                    <div>
                                        {statusname}
                                    </div>
                                    {(isAdmin && approveenable || isAdmin && banenable) ?
                                        <div className={style.innerButtons}>
                                            <AdminPost
                                                curlang={curlang}
                                                approveenable={approveenable}
                                                banenable={banenable}
                                                approvefunction={approvefunction}
                                                banfunction={banfunction}
                                                post={post}
                                            />
                                        </div> : null
                                    }
                                </div>
                            )

                        }
                        return null
                    }
                }
            ]

            return (
                <MetaData
                    rowstyle={{ width: '50%' }}
                    post={post}
                    style={mds}
                    data={data}
                />
            )
        }
        return null
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },


    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div></div>
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    //Title
    getTitle: function() {

        const { construct } = this.props
        const {
            route,
            routes = {},
            curlang = {}
        } = construct.props

        const {
            userpostssubtitle = 'Posts',
            userpoststrashsubtitle = 'Deleted posts',
            userimagesssubtitle = 'Images',
            userimagesstrashsubtitle = 'Deleted images'
        } = curlang

        const {
            wapplruserapppostsslug = '/posts',
            wapplruserappimagesslug = '/images'
        } = routes

        let userpoststitle = 'User posts'

        if (route === wapplruserapppostsslug) userpoststitle = userpostssubtitle
        if (route === wapplruserapppostsslug + '/trash') userpoststitle = userpoststrashsubtitle
        if (route === wapplruserappimagesslug) userpoststitle = userimagesssubtitle
        if (route === wapplruserappimagesslug + '/trash') userpoststitle = userimagesstrashsubtitle

        return userpoststitle
    },
    render: function() {

        const { construct } = this.props
        const {
            style,
            history,
            searchData,
            feedRefs,
            feedDatas,
            postSlug,
            postSearchObjectName,
            curlang = {},
            getFetch,
            getthumb
        } = construct.props


        const historyHelpers = searchData.getHistoryHelpers()
        const state = this.state
        const setRef = this.setRef

        const deleteicon = this.deleteicon
        const restoreicon = this.restoreicon
        const editicon = this.editicon
        const publicicon = this.publicicon
        const contentcomponent = this.contentcomponent
        const globalstate = this.getGlobalState()
        const scrollDiv = ('undefined' !== typeof document) ? document.body : null

        const title = this.getTitle()

        const input = {
            type: 'gallery',
            style: gs,
            history,
            searchData,
            feedRefs,
            feedDatas,
            postSlug,
            postSearchObjectName,
            curlang,
            historyHelpers,
            deleteicon,
            restoreicon,
            editicon,
            publicicon,
            title,
            globalstate,
            getFetch,
            setRef,
            scrollDiv,
            getthumb,
            feedprops: {
                contentcomponent
            },
            ...state
        }

        return (
            <div>
                <Wapplrgallery {...input} />
                <Wapplrdialog setRef={setRef} className={style.dialog} />
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
            </div>
        )
    }
})
