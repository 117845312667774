export default {
    postimagetitle: 'Kép',
    menuimagetitle: 'Kép',
    editimagetitle: 'Kép szerkesztése',
    newimagetitle: 'Új kép',
    userimagessubtitle: 'Képek',
    userimagesstrashsubtitle: 'Törölt képek',
    wapplruserappimagesname: 'Képek',
    wapplruserappimagestitle: 'Felhasználó képei',
    wapplruserappimagestrash: 'Törölt képek',
    saveimagesuccessmessage: 'A kép elmentve',
    saveimagedefaultfail: 'Hiba történt a kép feltöltése közben, próbálkozzon újra',
    saveimagessuccessmessage: 'Az összes kép feltöltve',
    saveimagesdefaultfail: function({ length, hmsuccess }) {
        return 'Elküldött képek: ' + length + ', feltöltve: ' + hmsuccess
    },
    uploaddefaultfail: 'Hibás / hiányzó file',
    uploadinvalidfiletype: function(a) {
        return 'Érvénytelen file tipus, engedélyezetett: ' + a
    },
    uploadtoobig: function(a) {
        return 'Túl nagy méret, max ' + a + ' MB'
    },

    imagesearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Keresés a képek között'
        if (username && !taxs) r = 'Keresés ' + username + ' képei között'
        if (!username && taxs) r = 'Keresés a képek között a ' + taxs + ' kategóriában'
        if (username && taxs) r = 'Keresés ' + username + ' képei között, a ' + taxs + ' kategóriában'
        return r
    },

    metauploaddate: 'Feltöltés dátuma'
}
