export function fetchs({ postNormalise, defaultRequest, defaultRequestFiles, name }) {

    return {
        saveimage: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                postNormalise({ data })
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/save'
                })
            }
        },
        deleteimage: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/delete'
                })
            }
        },
        newimage: function({ getHelpers, curlang, uploadconfig, success, fail, multiple, history, replacefromstate }) {
            return async function(data, event, resetForm, invalidateForm, refs) {
                postNormalise({ data })
                await defaultRequestFiles({
                    getHelpers,
                    curlang,
                    uploadconfig,
                    success,
                    fail,
                    multiple,
                    history,
                    replacefromstate,
                    data,
                    event,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/new'
                })
            }
        },
        banimage: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/ban'
                })
            }
        },
        approveimage: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/approve'
                })
            }
        }
    }
}
