import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import ps from '../../../../themes/components/Wapplrpost.css'

import avs from '../../../../themes/components/Wapplravatar.css'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'

import { getPostStatusData } from '../../../server/poststatus/statuses'

const Wapplrpost = wapplrcomponents.post

export default createReactClass({
    getInitialState: function() {
        return {
            isMounted: false,
            post: null
        }
    },
    getPost: function(response) {
        return (response && response.currentimage && response.currentimage[0]) ? response.currentimage[0] : null
    },
    componentDidMount: function() {
        const { response } = this.props
        const post = this.getPost(response)

        this.setState({
            isMounted: true,
            post: post
        })
    },
    getPostEditLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/image/edit/' + post.id
        }
        return ''
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    setStatusName: function({ statusname }) {
        let s = statusname
        const me = this.getMe()
        const admin = me && me.isAdmin || me && me.isEditor

        if (!admin) {
            if (s === 'deleted itself') {
                s = 'deleted'
            }
            if (s === 'created') {
                s = 'published'
            }
            if (s === 'required data') {
                s = 'published'
            }
            if (s === 'approved') {
                s = 'published'
            }
        }

        return s
    },
    getPostStatus: function() {
        const { curlang } = this.props
        const post = this.state.post
        return getPostStatusData({ post, curlang })
    },

    //Publicicon
    publicicon: function({ post }) {

        const share = post && post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },

    render: function() {

        const {
            history,
            style,
            Parent = TemplateDefault,
            postStyle = ps,
            getthumb,
            getthumbstyle
        } = this.props
        const post = this.state.post
        const author = (post && post.author) ? post.author : null
        const me = this.getMe()
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)
        const edit = canEdit ? this.getPostEditLink() : null
        const { statusname, deleted } = this.getPostStatus()
        const publiciconcontent = this.publicicon({ post })

        const bgstyle = (post && post.id) ? { backgroundImage: 'url(\'' + post.cover + '\')' } : null

        return (
            <Parent {...this.props} className={style.postcard}>
                <div style={bgstyle} className={(bgstyle) ? postStyle.postimagecontainer : null}></div>
                <Wapplrpost
                    history={history}
                    className={'cardtype'}
                    style={postStyle}
                    post={this.state.post}
                    showauthor={true}
                    avatarstyle={avs}
                    edit={edit}
                    statusname={(deleted) ? statusname : null}
                    publiciconcontent={publiciconcontent}
                    getthumb={getthumb}
                    getthumbstyle={getthumbstyle}
                />
            </Parent>
        )
    }
})


