import React from 'react'
import createReactClass from 'create-react-class'

import ps from '../../../../themes/components/Wapplrpost.css'

import TemplateDefault from '../../../templates/template_default'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'
import Wapplrdialog from '../../../components/Wapplrdialog'

import fetchs from '../../../services/fetchs'

import Uploadbutton from '../../../components/Uploadbutton'
import us from '../../../components/Uploadbutton/Uploadbutton.css'

const Post = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            isMounted: false,
            post: null
        }
    },
    componentDidMount: function() {
        const { response, curlang = {}, uploadconfig, history } = this.props

        const {
            saveimagessuccessmessage = 'All image were uploaded',
            saveimagesdefaultfail = function({ length, hmsuccess }) {
                return 'Sended images:' + length + ', success uploaded:' + hmsuccess
            }
        } = curlang

        const post = this.getPost(response)
        const getPostLink = this.getPostLink
        const getUserImagesLink = this.getUserImagesLink
        const historyPush = this.historyPush
        const me = this.getMe()
        const snack = this.snack

        this.savefetch = fetchs.newimage({
            getHelpers: this.getHelpers,
            history: history,
            curlang: curlang,
            uploadconfig: uploadconfig,
            multiple: function({ response, processingEnd }) {
                if (response.length) {

                    let hmsuccess = 0
                    let userid = me.id
                    response.map(function(r) {
                        if (r.success) hmsuccess = hmsuccess + 1
                        if (r.userId) userid = r.userId
                    })
                    const message = (response.length === hmsuccess) ? saveimagessuccessmessage : saveimagesdefaultfail({
                        length: response.length,
                        hmsuccess
                    })
                    snack(message)
                    processingEnd()

                    let wassucc = false
                    response.map(function(i) {
                        if (i.success) {
                            wassucc = true
                        }
                    })

                    if (wassucc) {
                        if (response.length === 1 && response[0].success && response[0].postId) {
                            setTimeout(function() {
                                processingEnd({ reset: true })
                                historyPush(getPostLink({ id: response[0].postId }))
                            }, 3000)
                        } else {
                            setTimeout(function() {
                                processingEnd({ reset: true })
                                historyPush(getUserImagesLink({ id: userid }))
                            }, 3000)
                        }
                    } else {
                        setTimeout(function() {
                            processingEnd({ reset: true })
                        }, 3000)
                    }

                } else {


                    const message = response.message || savepostdefaultfail
                    snack(message)
                    setTimeout(function() {
                        processingEnd({ reset: true })
                    }, 1500)
                }
            }
        })



        this.setState({
            isMounted: true,
            post: post
        })

    },

    //Close

    close: function() {

        const { state } = this.getHelpers()

        const getUserLink = this.getUserLink
        const historyPush = this.historyPush

        if (state.lasturl) {
            historyPush(state.lasturl)
        } else {
            historyPush(getUserLink())
        }
    },

    //Edit functions
    onchange: function(e) {



        const uploadbutton = this.refElements['uploadbutton']
        const processingStart = uploadbutton.setProcess
        const processingEnd = uploadbutton.endProcess
        const resetForm = function() {
        }
        const invalidateForm = function() {
        }
        uploadbutton.setFiles({ files: null })

        this.savepost({}, e, resetForm, invalidateForm, { processingStart, processingEnd })
    },

    savefetch: function() {
    },
    savepost: function(data, event, resetForm, invalidateForm, refs) {
        const post = this.state.post
        if (post && post.id) {
            data.id = post.id
            this.savefetch(data, event, resetForm, invalidateForm, refs)
        }
    },

    //image helpers
    getPostLink: function(ipost) {
        const post = ipost || this.state.post
        if (post && post.id) {
            return '/image/' + post.id
        }
        return ''
    },
    getUserLink: function(user) {
        return (user && user.id) ? '/user/' + user.id : ''
    },
    getUserImagesLink: function(user) {
        return (user && user.id) ? '/user/images/' + user.id : ''
    },
    getPost: function() {
        return {
            id: 1,
            title: 'New image'
        }
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getEditData: function() {
        const { editdata } = this.props
        return editdata.image
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },

    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div></div>
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    render: function() {

        const { style, curlang, uploadconfig = {} } = this.props
        const { uploadtext, newimagetitle = 'New image' } = curlang


        this.getEditData()


        const setRef = this.setRef
        const me = this.getMe()
        const onChange = this.onchange

        const {
            enableuploadtypes
        } = uploadconfig

        let enableattr = 'image/*'
        if (enableuploadtypes && enableuploadtypes.length) {
            enableattr = ''
            enableuploadtypes.map(function(a) {
                enableattr = (!enableattr) ? 'image/' + a : enableattr + ',image/' + a
            })
        }

        return (
            <div>
                {(me && me.id) ?
                    <div>
                        <div className={style.posttitle}>{newimagetitle}</div>
                        <Uploadbutton style={us} setRef={setRef} uploadText={uploadtext}
                                      inputprops={{ onChange: onChange, multiple: true, accept: enableattr }} />
                    </div>
                    : null
                }
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
                <Wapplrdialog setRef={setRef} className={ps.dialog} />
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        const { Parent = TemplateDefault } = this.props
        return (
            <Parent {...this.props} >
                <Post {...this.props} />
            </Parent>
        )
    }
})
