export default {
    postimagetitle: 'Image',
    menuimagetitle: 'Image',
    editimagetitle: 'Edit image',
    newimagetitle: 'New image',
    userimagessubtitle: 'Images',
    userimagesstrashsubtitle: 'Deleted images',
    wapplruserappimagesname: 'Images',
    wapplruserappimagestitle: 'User images',
    wapplruserappimagestrash: 'Deleted images',
    saveimagesuccessmessage: 'Your image has been saved',
    saveimagedefaultfail: 'Sorry, there was an issue save your image, please try again',
    saveimagessuccessmessage: 'All image were uploaded',
    saveimagesdefaultfail: function({ length, hmsuccess }) {
        return 'Sended images: ' + length + ', success uploaded: ' + hmsuccess
    },
    uploaddefaultfail: 'Invalid file',
    uploadinvalidfiletype: function(a) {
        return 'Invalid file type, enabled types: ' + a
    },
    uploadtoobig: function(a) {
        return 'Too big size, max ' + a + ' MB'
    },

    imagesearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Search in images'
        if (username && !taxs) r = 'Search in ' + username + '\'s images'
        if (!username && taxs) r = 'Search in images in ' + taxs + ' category'
        if (username && taxs) r = 'Search in ' + username + '\'s images, and in ' + taxs + ' category'
        return r
    },

    metauploaddate: 'Upload date'
}
