export function requests({ name, helpers }) {

    const defHelpName = name + 'Query'

    helpers[defHelpName] = function() {

        return `
        id
        title
        status
        subtitle
        state
        share
        publishedDate
        cover
        content_brief
        content_extended
        width
        height`
    }

    helpers[name] = function() {

        const userQuery = helpers.defaultUserQuery()

        return `
        id
        title
        author {
            ${userQuery}
        }
        authorstatus
        status
        subtitle
        state
        share
        publishedDate
        cover
        content_brief
        content_extended
        width
        height`
    }

    return {
        [name]: function() {
            return {
                path: '/graphql',
                data: {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: function({ state, params }) {

                        const query = (state && state.query) ? state.query : {}
                        const replacequery = (params && params[name]) ? { id: params[name] } : null

                        if (replacequery) {
                            Object.keys(replacequery).map(function(k) {
                                query[k] = replacequery[k]
                            })
                        }

                        let id = (state && state.query && state.query[name]) ? state.query[name] : null
                        if (!id) id = (state && state.query && state.query.id) ? state.query.id : null

                        const postQuery = helpers[name]()
                        const reqName = 'current' + name

                        return JSON.stringify({
                            query: `{
                          ${reqName}(id:"${id}") {
                            ${postQuery}
                          }
                        }`
                        })
                    },
                    credentials: 'include'
                }
            }
        }
    }
}
